import React, { useState, useCallback } from 'react';
import { Dialog, DialogContent, DialogTitle, Stepper, Step, StepLabel, Box, Button, Typography } from '@mui/material';
import ProductSummaryStep from './ProductSummaryStep';
import IndustryStep from './IndustryStep';
import TargetCustomerStep from './TargetCustomerStep';
import GeographicFocusStep from './GeographicFocusStep';

const STEPS = [
  {
    label: 'Product Summary',
    description: 'Tell us about your product',
    key: 'productSummary'
  },
  {
    label: 'Industry',
    description: 'Tell us about your company',
    key: 'userIndustry'
  },
  {
    label: 'Target Customer',
    description: 'Define your target customer profile',
    key: 'targetCustomer'
  },
  {
    label: 'Geographic Focus',
    description: 'Tell us where you sell',
    key: 'targetCustomer.geographicFocus'
  }
];

const ProfileSetupModal = ({ open, onClose, existingProfile, onSaveProgress, isLoading, onComplete }) => {
  const [activeStep, setActiveStep] = useState(() => {
    // If profile is complete, always start at beginning
    if (existingProfile?.profile?.completionStatus?.overallPercentage === 100) {
        return 0;
    }
    // Default to beginning for incomplete or new profiles
    return 0;
});
  // Restructured form data to match backend schema
  const [formData, setFormData] = useState({
    productSummary: {
      url: existingProfile?.productSummary?.url || '',
      summary: existingProfile?.productSummary?.summary || ''
    },
    userIndustry: {
      primaryUserIndustry: existingProfile?.profile?.userIndustry?.primaryUserIndustry || '',
      userSubIndustry: existingProfile?.profile?.userIndustry?.userSubIndustry || '',
      otherIndustry: existingProfile?.profile?.userIndustry?.otherIndustry || ''
    },
    targetCustomer: {
      companySizes: existingProfile?.profile?.targetCustomer?.companySizes || [],
      decisionMakerLevels: existingProfile?.profile?.targetCustomer?.decisionMakerLevels || [],
      decisionMakerDepartments: existingProfile?.profile?.targetCustomer?.decisionMakerDepartments || [],
      targetIndustries: existingProfile?.profile?.targetCustomer?.targetIndustries || [],
      geographicFocus: {
        geographicFocus: existingProfile?.profile?.targetCustomer?.geographicFocus?.geographicFocus || null,
        regions: existingProfile?.profile?.targetCustomer?.geographicFocus?.regions || [],
        states: existingProfile?.profile?.targetCustomer?.geographicFocus?.states || []
      }
    }
  });

  const getCurrentSection = useCallback(() => STEPS[activeStep].key, [activeStep]);

  const handleStepChange = useCallback((stepData) => {
    const currentSection = getCurrentSection();
    
    setFormData(prev => {
      // Skip update if data hasn't changed for non-geographic sections
      if (currentSection !== 'targetCustomer.geographicFocus' && 
          JSON.stringify(prev[currentSection]) === JSON.stringify(stepData)) {
          return prev;
      }

      const newState = currentSection === 'targetCustomer.geographicFocus' 
        ? {
            ...prev,
            targetCustomer: {
                ...prev.targetCustomer,
                geographicFocus: stepData
            }
          }
        : {
            ...prev,
            [currentSection]: stepData
          };
      
      return newState;
    });
}, [getCurrentSection]);

  const isStepComplete = useCallback(() => {
    const currentSection = getCurrentSection();
    const data = formData[currentSection];

    switch (activeStep) {
      case 0: // Product Summary
        return !!data?.summary;

      case 1: // User Industry
        return !!data?.primaryUserIndustry;

      case 2: // Target Customer
        return data?.companySizes?.length > 0 &&
          data?.decisionMakerLevels?.length > 0 &&
          data?.targetIndustries?.length > 0;

          case 3: // Geographic Focus
          const geoFocus = formData.targetCustomer?.geographicFocus?.geographicFocus;
            const isComplete = !!geoFocus;
            return isComplete;

      default:
        return false;
    }
  }, [activeStep, formData, getCurrentSection]);

  const handleNext = useCallback(async () => {
    const currentSection = getCurrentSection();

    try {
      if (currentSection === 'targetCustomer.geographicFocus') {
        await onSaveProgress(currentSection, formData.targetCustomer);
      } else {
        await onSaveProgress(currentSection, formData[currentSection]);
      }

      if (activeStep < STEPS.length - 1) {
        setActiveStep(prev => prev + 1);
      } else {
        if (onComplete) {
          onComplete();
        }
        onClose();
      }
    } catch (error) {
      console.error('Error saving profile section:', error);
    }
  }, [getCurrentSection, formData, activeStep, onSaveProgress, onClose, onComplete]);

  const handleBack = useCallback(() => {
    setActiveStep(prev => prev - 1);
  }, []);

  const renderStepContent = useCallback(() => {
    switch (activeStep) {
      case 0:
        return (
          <ProductSummaryStep
            data={formData.productSummary}
            onChange={handleStepChange}
            isLoading={isLoading}
          />
        );
      case 1:
        return (
          <IndustryStep
            data={formData.userIndustry}
            onChange={handleStepChange}
            isLoading={isLoading}
          />
        );
      case 2:
        return (
          <TargetCustomerStep
            data={formData.targetCustomer}
            onChange={handleStepChange}
            isLoading={isLoading}
          />
        );
      case 3:
        return (
          <GeographicFocusStep
            data={formData.targetCustomer.geographicFocus}
            onChange={handleStepChange}
            isLoading={isLoading}
          />
        );
      default:
        return null;
    }
  }, [activeStep, formData, handleStepChange, isLoading]);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        sx: { minHeight: '500px' }
      }}
    >
      <DialogTitle>
        <Typography variant="h5" component="div">
          Complete Your Profile
        </Typography>
        <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
          {STEPS[activeStep].description}
        </Typography>
      </DialogTitle>

      <DialogContent>
        <Box sx={{ width: '100%', mt: 2 }}>
          <Stepper activeStep={activeStep}>
            {STEPS.map((step) => (
              <Step key={step.label}>
                <StepLabel>{step.label}</StepLabel>
              </Step>
            ))}
          </Stepper>

          <Box sx={{ mt: 4, mb: 2 }}>
            {renderStepContent()}
          </Box>

          <Box sx={{ 
            display: 'flex', 
            justifyContent: 'space-between',
            pt: 2,
            borderTop: 1,
            borderColor: 'divider'
          }}>
            <Button
              onClick={handleBack}
              disabled={activeStep === 0 || isLoading}
            >
              Back
            </Button>
            <Box>
              <Button
                onClick={onClose}
                sx={{ mr: 1 }}
                disabled={isLoading}
              >
                Save & Exit
              </Button>
              <Button
                variant="contained"
                onClick={handleNext}
                disabled={!isStepComplete() || isLoading}
              >
                {activeStep === STEPS.length - 1 ? 'Complete' : 'Continue'}
              </Button>
            </Box>
          </Box>
        </Box>
      </DialogContent>
    </Dialog>
  );
};

export default React.memo(ProfileSetupModal);