export const PROFILE_CONSTANTS = {
    INDUSTRIES: {
        SAAS: 'SaaS/Technology',
        INSURANCE: 'Insurance',
        FINANCIAL: 'Financial Services',
        PROFESSIONAL: 'Professional Services',
        OTHER: 'Other'
    },

    COMPANY_SIZES: [
        '1-10 employees',
        '11-50 employees',
        '51-200 employees',
        '201-500 employees',
        '501+ employees'
    ],

    TARGET_INDUSTRIES: {
        TECH: {
            name: 'Technology & Software',
            subIndustries: [
                'Enterprise Software',
                'IT Services',
                'Cloud Services',
                'Cybersecurity',
                'Digital Marketing',
                'Other Tech'
            ]
        },
        FINANCIAL: {
            name: 'Financial Services',
            subIndustries: [
                'Banking',
                'Investment Services',
                'Insurance',
                'Accounting',
                'Financial Technology',
                'Other Finance'
            ]
        },
        HEALTHCARE: {
            name: 'Healthcare & Medical',
            subIndustries: [
                'Hospitals',
                'Private Practices',
                'Medical Services',
                'Healthcare Technology',
                'Other Healthcare'
            ]
        },
        PROFESSIONAL: {
            name: 'Professional Services',
            subIndustries: [
                'Legal Services',
                'Consulting Firms',
                'Accounting Services',
                'Marketing Agencies',
                'Other Professional'
            ]
        },
        MANUFACTURING: {
            name: 'Manufacturing',
            subIndustries: [
                'Industrial Manufacturing',
                'Consumer Goods',
                'Food & Beverage',
                'Electronics',
                'Other Manufacturing'
            ]
        },
        RETAIL: {
            name: 'Retail & Consumer',
            subIndustries: [
                'Retail Stores',
                'Restaurants',
                'Hospitality',
                'Consumer Services',
                'Other Retail'
            ]
        },
        CONSTRUCTION: {
            name: 'Construction & Real Estate',
            subIndustries: [
                'Construction Companies',
                'Real Estate Agencies',
                'Property Management',
                'Architecture Firms',
                'Other Construction/RE'
            ]
        },
        EDUCATION: {
            name: 'Education',
            subIndustries: [
                'K-12 Schools',
                'Higher Education',
                'Education Services',
                'Training Organizations',
                'Other Education'
            ]
        }
    },

    DECISION_MAKER_LEVELS: [
        'C-Suite',
        'VP/Director Level',
        'Department Head/Manager',
        'Business Owner',
        'Startup Founder',
        'Other'
    ],

    DECISION_MAKER_DEPARTMENTS: [
        'Sales',
        'Marketing',
        'Operations',
        'Finance',
        'Legal',
        'HR',
        'IT',
        'Engineering',
        'Product',
        'Customer Support',
        'Other'
    ],

    REGIONS: [
        'Northeast',
        'Southeast',
        'Midwest',
        'Southwest',
        'West Coast',
        'Northwest'
    ],

    GEOGRAPHIC_FOCUS_TYPES: {
        NONE: 'None',
        REGIONAL: 'Regional',
        STATE: 'State'
    },

    STATES: [
        'Alabama', 'Alaska', 'Arizona', 'Arkansas', 'California',
        'Colorado', 'Connecticut', 'Delaware', 'Florida', 'Georgia',
        'Hawaii', 'Idaho', 'Illinois', 'Indiana', 'Iowa',
        'Kansas', 'Kentucky', 'Louisiana', 'Maine', 'Maryland',
        'Massachusetts', 'Michigan', 'Minnesota', 'Mississippi', 'Missouri',
        'Montana', 'Nebraska', 'Nevada', 'New Hampshire', 'New Jersey',
        'New Mexico', 'New York', 'North Carolina', 'North Dakota', 'Ohio',
        'Oklahoma', 'Oregon', 'Pennsylvania', 'Rhode Island', 'South Carolina',
        'South Dakota', 'Tennessee', 'Texas', 'Utah', 'Vermont',
        'Virginia', 'Washington', 'West Virginia', 'Wisconsin', 'Wyoming'
    ]
};

// Validation helpers
export const isValidIndustry = (industry) => 
    Object.values(PROFILE_CONSTANTS.INDUSTRIES).includes(industry);

export const isValidMarketFocus = (focus) => 
    Object.values(PROFILE_CONSTANTS.MARKET_FOCUS).includes(focus);

export const isValidCompanySize = (size) =>
    PROFILE_CONSTANTS.COMPANY_SIZES.includes(size);

export const isValidRegion = (region) =>
    PROFILE_CONSTANTS.REGIONS.includes(region);

export const isValidState = (state) =>
    PROFILE_CONSTANTS.STATES.includes(state);

export const isValidGeographicFocusType = (type) =>
    Object.values(PROFILE_CONSTANTS.GEOGRAPHIC_FOCUS_TYPES).includes(type);

export const isValidDecisionMakerLevel = (level) =>
    PROFILE_CONSTANTS.DECISION_MAKER_LEVELS.includes(level);

export const isValidConsumerDemographic = (demographic) =>
    PROFILE_CONSTANTS.CONSUMER_DEMOGRAPHICS.includes(demographic);