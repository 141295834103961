import React, { useState, useEffect } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, TextField, Typography, CircularProgress } from '@mui/material';

function ProductSummaryModal({ open, onClose, onSave, existingSummary }) {
    const [summary, setSummary] = useState(existingSummary?.summary || '');
    const [url, setUrl] = useState(existingSummary?.url || '');
    const [isGenerating, setIsGenerating] = useState(false);
    const [error, setError] = useState('');
    const [showSummaryField, setShowSummaryField] = useState(false);
    const [isNewUser, setIsNewUser] = useState(true);
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    useEffect(() => {
        setSummary(existingSummary?.summary || '');
        setUrl(existingSummary?.url || '');
        setShowSummaryField(!!existingSummary?.summary);
        setIsNewUser(!existingSummary || !existingSummary.summary);
    }, [existingSummary]);

    const handleGenerateSummary = async () => {
        setIsGenerating(true);
        setError('');
        try {
            const response = await fetch(`${apiBaseUrl}/api/scrape/scrape-summary`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true',
                },
                body: JSON.stringify({ url }),
            });
            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || 'Failed to generate product summary');
            }
            const data = await response.json();
            setSummary(data.summary);
            setShowSummaryField(true);
            setIsNewUser(false);
        } catch (error) {
            console.error('Error generating product summary:', error);
            setError(error.message || 'Failed to generate product summary. Please try again.');
            setSummary('');
            setShowSummaryField(false);
        } finally {
            setIsGenerating(false);
        }
    };

    const handleSave = () => {
        onSave({ summary, url }, { closeModal: true });
    };

    const handleClose= () => {
        setUrl(existingSummary?.url || '');
        setSummary(existingSummary?.summary || '');
        setShowSummaryField(!!existingSummary?.summary);
        setIsNewUser(!existingSummary || !existingSummary.summary);
        setError('');
        onClose();
    };

    return (
        <Dialog open={open} onClose={handleClose}>
            <DialogTitle>{existingSummary ? 'Edit Your Product Summary' : 'Complete Your Profile'}</DialogTitle>
            <DialogContent>
                <Typography variant="body1" gutterBottom>
                    {existingSummary?.summary
                        ? 'Enter a new company URL to regenerate a summary, or edit the existing product summary.'
                        : 'To personalize your experience and generate realistic customer personas, please provide your company\'s website URL below.'}
                </Typography>
                <TextField
                    value={url}
                    onChange={(e) => setUrl(e.target.value)}
                    fullWidth
                    margin="normal"
                    placeholder="Enter your company URL"
                />
                <Button
                    onClick={handleGenerateSummary}
                    variant="contained"
                    color="primary"
                    disabled={isGenerating || !url}
                    style={{ marginTop: '10px', marginBottom: '20px' }}
                >
                    {isGenerating ? <CircularProgress size={24} /> : (isNewUser || !summary ? 'Generate Summary' : 'Regenerate Summary')}
                </Button>
                {showSummaryField && (
                    <TextField
                        value={summary}
                        onChange={(e) => setSummary(e.target.value)}
                        fullWidth
                        multiline
                        rows={4}
                        margin="normal"
                        placeholder={summary ? '' : 'No summary available. Please enter a brief product summary.'}
                    />
                )}
                {error && (
                    <Typography color="error" style={{ marginTop: '10px' }}>
                        {error}
                    </Typography>
                )}     
            </DialogContent>
            <DialogActions>
                <Button onClick={handleClose}>Cancel</Button>
                {showSummaryField && (
                    <Button onClick={handleSave} variant="contained" color="primary" disabled={!summary}>
                        Save Summary
                    </Button>
                )}
            </DialogActions>
        </Dialog>
    );
}

export default ProductSummaryModal;