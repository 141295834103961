import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Chip, FormHelperText, Typography, OutlinedInput, Checkbox, ListItemText} from '@mui/material';
import { PROFILE_CONSTANTS } from '../../shared/constants/profileConstants';

const TargetCustomerStep = ({ data, onChange, isLoading }) => {
    const [formData, setFormData] = useState({
        companySizes: data?.companySizes || [],
        decisionMakerLevels: data?.decisionMakerLevels || [],
        decisionMakerDepartments: data?.decisionMakerDepartments || [],
        targetIndustries: data?.targetIndustries?.map(ti => ti.targetIndustry) || [],
        subIndustries: data?.targetIndustries?.reduce((acc, ti) => ({
            ...acc,
            [ti.targetIndustry]: ti.targetSubIndustries
        }), {}) || {}
    });

    const [touched, setTouched] = useState({
        companySizes: false,
        decisionMakerLevels: false,
        decisionMakerDepartments: false,
        targetIndustries: false
    });

    const [errors, setErrors] = useState({
        companySizes: '',
        decisionMakerLevels: '',
        decisionMakerDepartments: '',
        targetIndustries: '',
    });

    const mountedRef = useRef(true);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (data) {
            setFormData({
                companySizes: data.companySizes || [],
                decisionMakerLevels: data.decisionMakerLevels || [],
                decisionMakerDepartments: data.decisionMakerDepartments || [],
                targetIndustries: data.targetIndustries?.map(ti => ti.targetIndustry) || [],
                subIndustries: data.targetIndustries?.reduce((acc, ti) => ({
                    ...acc,
                    [ti.targetIndustry]: ti.targetSubIndustries
                }), {}) || {}
            });
            setTouched({
                companySizes: false,
                decisionMakerLevels: false,
                decisionMakerDepartments: false,
                targetIndustries: false
            });
            setErrors({
                companySizes: '',
                decisionMakerLevels: '',
                decisionMakerDepartments: '',
                targetIndustries: '',
            });
        }
    }, [data]);

    const needsDepartments = useMemo(() =>
        formData.decisionMakerLevels.some(
            level => !['Startup Founder', 'Business Owner'].includes(level)
        ),
        [formData.decisionMakerLevels]
    );

    const validateForm = useCallback(() => {
        if (!mountedRef.current) return false;

        const newErrors = {
            companySizes: !formData.companySizes.length
                ? 'Please select at least one company size' 
                : '',
            decisionMakerLevels: !formData.decisionMakerLevels.length
                ? 'Please select at least one decision maker level' 
                : '',
            decisionMakerDepartments: needsDepartments && !formData.decisionMakerDepartments.length
                ? 'Please select at least one department' 
                : '',
            targetIndustries: !formData.targetIndustries.length
                ? 'Please select at least one target industry' 
                : '',
        };

        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    }, [formData, needsDepartments]);

    const transformAndUpdateData = useCallback(() => {
        if (!mountedRef.current || !Object.values(touched).some(t => t)) return;

        if (validateForm()) {
            const transformedData = {
                companySizes: formData.companySizes,
                decisionMakerLevels: formData.decisionMakerLevels,
                decisionMakerDepartments: formData.decisionMakerDepartments,
                targetIndustries: formData.targetIndustries.map(industry => ({
                    targetIndustry: industry,
                    targetSubIndustries: formData.subIndustries[industry] || []
                }))
            };

            const currentData = {
                companySizes: data?.companySizes || [],
                decisionMakerLevels: data?.decisionMakerLevels || [],
                decisionMakerDepartments: data?.decisionMakerDepartments || [],
                targetIndustries: (data?.targetIndustries || []).map(ti => ({
                    targetIndustry: ti.targetIndustry,
                    targetSubIndustries: ti.targetSubIndustries || []
                }))
            };

            if (JSON.stringify(transformedData) !== JSON.stringify(currentData)) {
                onChange(transformedData);
            }
        }
    }, [formData, onChange, validateForm, touched, data]);

    useEffect(() => {
        if (!Object.values(touched).some(t => t)) return;

        const timeoutId = setTimeout(() => {
            if (mountedRef.current) {
                transformAndUpdateData();
            }
        }, 500);
        
        return () => clearTimeout(timeoutId);
    }, [touched, transformAndUpdateData]);

    const handleIndustryChange = useCallback((event) => {
        const selectedIndustries = event.target.value;
        setFormData(prev => {
            const updatedSubIndustries = { ...prev.subIndustries };
            Object.keys(updatedSubIndustries).forEach(industry => {
                if (!selectedIndustries.includes(industry)) {
                    delete updatedSubIndustries[industry];
                }
            });

            return {
                ...prev,
                targetIndustries: selectedIndustries,
                subIndustries: updatedSubIndustries
            };
        });
        setTouched(prev => ({ ...prev, targetIndustries: true }));
    }, []);

    const handleSubIndustryChange = useCallback((industry) => (event) => {
        const selectedSubIndustries = event.target.value;
        setFormData(prev => ({
            ...prev,
            subIndustries: {
                ...prev.subIndustries,
                [industry]: selectedSubIndustries
            }
        }));
        setTouched(prev => ({ ...prev, targetIndustries: true }));
    }, []);

    const handleCompanySizeChange = useCallback((event) => {
        const value = event.target.value;
        setFormData(prev => ({
            ...prev,
            companySizes: value
        }));
        setTouched(prev => ({ ...prev, companySizes: true }));
    }, []);

    const handleDecisionMakerLevelChange = useCallback((event) => {
        const selectedLevels = event.target.value;
        const needsDepts = selectedLevels.some(level =>
            !['Startup Founder', 'Business Owner'].includes(level)
        );

        setFormData(prev => ({
            ...prev,
            decisionMakerLevels: selectedLevels,
            decisionMakerDepartments: needsDepts ? prev.decisionMakerDepartments : []
        }));
        setTouched(prev => ({ ...prev, decisionMakerLevels: true }));
    }, []);

    const handleDepartmentChange = useCallback((event) => {
        setFormData(prev => ({
            ...prev,
            decisionMakerDepartments: event.target.value
        }));
        setTouched(prev => ({ ...prev, decisionMakerDepartments: true }));
    }, []);

    const getFieldError = useCallback(
        (field) => touched[field] ? errors[field] : '',
        [errors, touched]
    );

    const renderSubIndustrySelects = useCallback(() => {
        return formData.targetIndustries.map(industry => {
            const industryKey = Object.keys(PROFILE_CONSTANTS.TARGET_INDUSTRIES)
                .find(key => PROFILE_CONSTANTS.TARGET_INDUSTRIES[key].name === industry);
            
            const subIndustries = PROFILE_CONSTANTS.TARGET_INDUSTRIES[industryKey]?.subIndustries || [];
            
            if (subIndustries.length === 0) return null;

            return (
                <FormControl 
                    key={industry} 
                    fullWidth 
                    sx={{ mt: 2 }}
                    disabled={isLoading}
                >
                    <InputLabel>{`${industry} Sub-Industries`}</InputLabel>
                        <Select
                            multiple
                            value={formData.subIndustries[industry] || []}
                            onChange={handleSubIndustryChange(industry)}
                            input={<OutlinedInput label={`${industry} Sub-Industries`} />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                        >
                            {subIndustries.map((subIndustry) => (
                                <MenuItem key={subIndustry} value={subIndustry}>
                                    <Checkbox 
                                        checked={formData.subIndustries[industry]?.includes(subIndustry) || false}
                                    />
                                    <ListItemText primary={subIndustry} />
                                </MenuItem>
                            ))}
                        </Select>
                </FormControl>
            );
        });
    }, [formData.targetIndustries, formData.subIndustries, handleSubIndustryChange, isLoading]);

    return (
        <Box sx={{ py: 2 }}>
            <Typography variant="h6" gutterBottom>
                Define Your Target Customer
            </Typography>

            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Select all options for who you typically sell to.
            </Typography>

            <FormControl
                fullWidth 
                error={!!getFieldError('companySizes')} 
                sx={{ mb: 3 }}
                disabled={isLoading}
            >
                <InputLabel>Target Company Size</InputLabel>
                <Select
                    multiple
                    value={formData.companySizes}
                    onChange={handleCompanySizeChange}
                    input={<OutlinedInput label="Target Company Sizes" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                >
                    {PROFILE_CONSTANTS.COMPANY_SIZES.map((size) => (
                        <MenuItem key={size} value={size}>
                            <Checkbox checked={formData.companySizes.includes(size)} />
                            <ListItemText primary={size} />
                        </MenuItem>
                    ))}
                </Select>
                {getFieldError('companySizes') && (
                    <FormHelperText>{getFieldError('companySizes')}</FormHelperText>
                )}
            </FormControl>

            <FormControl
                fullWidth 
                error={!!getFieldError('decisionMakerLevels')} 
                sx={{ mb: 3 }}
                disabled={isLoading}
            >
                <InputLabel>Decision Maker Levels</InputLabel>
                <Select
                    multiple
                    value={formData.decisionMakerLevels}
                    onChange={handleDecisionMakerLevelChange}
                    input={<OutlinedInput label="Decision Maker Levels" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                >
                    {PROFILE_CONSTANTS.DECISION_MAKER_LEVELS.map((level) => (
                        <MenuItem key={level} value={level}>
                            <Checkbox checked={formData.decisionMakerLevels.includes(level)} />
                            <ListItemText primary={level} />
                        </MenuItem>
                    ))}
                </Select>
                {getFieldError('decisionMakerLevels') && (
                    <FormHelperText>{getFieldError('decisionMakerLevels')}</FormHelperText>
                )}
            </FormControl>
            
            {needsDepartments && (
                <Box sx={{ mb: 3}}>
                    <FormControl 
                        fullWidth 
                        error={!!getFieldError('decisionMakerDepartments')}
                        disabled={isLoading}
                    >
                        <InputLabel>Target Departments</InputLabel>
                        <Select
                            multiple
                            value={formData.decisionMakerDepartments}
                            onChange={handleDepartmentChange}
                            input={<OutlinedInput label="Target Departments" />}
                            renderValue={(selected) => (
                                <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                                    {selected.map((value) => (
                                        <Chip key={value} label={value} />
                                    ))}
                                </Box>
                            )}
                        >
                            {PROFILE_CONSTANTS.DECISION_MAKER_DEPARTMENTS.map((dept) => (
                                <MenuItem key={dept} value={dept}>
                                    <Checkbox checked={formData.decisionMakerDepartments.includes(dept)} />
                                    <ListItemText primary={dept} />
                                </MenuItem>
                            ))}
                        </Select>
                        {getFieldError('decisionMakerDepartments') && (
                            <FormHelperText>{getFieldError('decisionMakerDepartments')}</FormHelperText>
                        )}
                    </FormControl>
                </Box>
            )}

            <FormControl 
                fullWidth 
                error={!!getFieldError('targetIndustries')} 
                sx={{ mb: 3 }}
                disabled={isLoading}
            >
                <InputLabel>Target Industries</InputLabel>
                <Select
                    multiple
                    value={formData.targetIndustries}
                    onChange={handleIndustryChange}
                    input={<OutlinedInput label="Target Industries" />}
                    renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                            {selected.map((value) => (
                                <Chip key={value} label={value} />
                            ))}
                        </Box>
                    )}
                >
                    {Object.keys(PROFILE_CONSTANTS.TARGET_INDUSTRIES).map((key) => (
                        <MenuItem 
                            key={key} 
                            value={PROFILE_CONSTANTS.TARGET_INDUSTRIES[key].name}
                        >
                            <Checkbox 
                                checked={formData.targetIndustries
                                    .includes(PROFILE_CONSTANTS.TARGET_INDUSTRIES[key].name)}
                            />
                            <ListItemText 
                                primary={PROFILE_CONSTANTS.TARGET_INDUSTRIES[key].name} 
                            />
                        </MenuItem>
                    ))}
                </Select>
                {getFieldError('targetIndustries') && (
                    <FormHelperText>{getFieldError('targetIndustries')}</FormHelperText>
                )}
            </FormControl>

            {renderSubIndustrySelects()}
        </Box>
    ); 
};

export default React.memo(TargetCustomerStep);