import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, FormControl, FormHelperText, InputLabel, MenuItem, Select, TextField, Typography } from '@mui/material';
import { PROFILE_CONSTANTS } from '../../shared/constants/profileConstants';

const INDUSTRY_DESCRIPTIONS = {
    [PROFILE_CONSTANTS.INDUSTRIES.SAAS]: 'Software companies and technology providers offering cloud-based solutions, platforms, or digital services.',
    [PROFILE_CONSTANTS.INDUSTRIES.INSURANCE]: 'Insurance providers and benefits brokers offering various types of coverage including life, health, property, or commercial insurance.',
    [PROFILE_CONSTANTS.INDUSTRIES.FINANCIAL]: 'Financial institutions, investment firms, banks, and companies providing financial products or services.',
    [PROFILE_CONSTANTS.INDUSTRIES.PROFESSIONAL]: 'Professional service firms including consulting, coaching, legal, accounting, or other specialized business services.',
    [PROFILE_CONSTANTS.INDUSTRIES.OTHER]: 'Select this if your industry is not listed above.'
};

const IndustryStep = ({ data, onChange, isLoading }) => {
    const [formData, setFormData] = useState({
        primaryUserIndustry: data?.primaryUserIndustry || '',
        userSubIndustry: data?.userSubIndustry || '',
        otherIndustry: data?.otherIndustry || ''
    });

    const [touched, setTouched] = useState({
        primaryUserIndustry: false,
        userSubIndustry: false,
        otherIndustry: false
    });

    const [errors, setErrors] = useState({
        primaryUserIndustry: '',
        userSubIndustry: '',
        otherIndustry: ''
    });

    const mountedRef = useRef(true);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    useEffect(() => {
        if (data) {
            setFormData({
                primaryUserIndustry: data.primaryUserIndustry || '',
                userSubIndustry: data.userSubIndustry || '',
                otherIndustry: data.otherIndustry || ''
            });
            setTouched({
                primaryUserIndustry: !!data.primaryUserIndustry,
                userSubIndustry: !!data.userSubIndustry,
                otherIndustry: !!data.otherIndustry
            });
            setErrors({
                primaryUserIndustry: '',
                userSubIndustry: '',
                otherIndustry: ''
            });
        }
    }, [data]);

    const validateForm = useCallback(() => {
        if (!mountedRef.current) return false;

        const newErrors = {
            primaryUserIndustry: !formData.primaryUserIndustry ? 'Please select your industry' : '',
            otherIndustry: formData.primaryUserIndustry === 'Other' && !formData.otherIndustry && touched.otherIndustry ? 'Please specify your industry' : ''
        };

        setErrors(newErrors);
        return !Object.values(newErrors).some(error => error);
    }, [formData, touched.otherIndustry]);

    const transformAndUpdateData = useCallback(() => {
        if (!mountedRef.current || !Object.values(touched).some(t => t)) return;

        if (validateForm()) {
            const transformedData = {
                primaryUserIndustry: formData.primaryUserIndustry,
                otherIndustry: formData.primaryUserIndustry === 'Other' ? formData.otherIndustry.trim() : '',
            };

            if (JSON.stringify(transformedData) !== JSON.stringify({
                primaryUserIndustry: data?.primaryUserIndustry || '',
                otherIndustry: data?.otherIndustry || ''
            })) {
                onChange(transformedData);
            }
        }
    }, [formData, onChange, validateForm, touched, data]);

    useEffect(() => {
        if (Object.values(touched).some(t => t)) {
          validateForm();
        }
      }, [validateForm, touched]);

    useEffect(() => {
        if (!Object.values(touched).some(t => t)) return;

        const timeoutId = setTimeout(() => {
            if (mountedRef.current) {
                transformAndUpdateData();
            }
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [touched, transformAndUpdateData])

    const handleIndustryChange = useCallback((event) => {
        const value = event.target.value;
        setFormData(prev => ({
            ...prev,
            primaryUserIndustry: value,
            otherIndustry: value === 'Other' ? prev.otherIndustry : ''
        }));
        setTouched(prev => ({
            ...prev,
            primaryUserIndustry: true,
            otherIndustry: false
        }));
    }, []);

    const handleOtherIndustryChange = useCallback((event) => {
        const value = event.target.value;
        setFormData(prev => ({
            ...prev,
            otherIndustry: value
        }));
        setTouched(prev => ({ ...prev, otherIndustry: true }));
    }, []);

    const handleBlur = useCallback((field) => () => {
        if (!mountedRef.current) return;
        setTouched(prev => ({ ...prev, [field]: true }));
    }, []);

    const getFieldError = useCallback(
        (field) => touched[field] ? errors[field] : '',
        [errors, touched]
    );

    return (
        <Box sx={{ py: 2 }}>
            <Typography variant="h6" gutterBottom>
                Tell us about your company
            </Typography>
          
            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Select the industry that best describes your company. This helps us generate more relevant customer personas and realistic sales scenarios.
            </Typography>
    
            <FormControl fullWidth error={!!errors.primaryUserIndustry} sx={{ mb: 3 }} disabled={isLoading}>
                <InputLabel>Your Industry</InputLabel>
                <Select
                    value={formData.primaryUserIndustry}
                    onChange={handleIndustryChange}
                    onBlur={handleBlur('primaryUserIndustry')}
                    label="Your Industry"
                >
                    {Object.values(PROFILE_CONSTANTS.INDUSTRIES).map((industry) => (
                        <MenuItem 
                            key={industry} 
                            value={industry}
                        >
                            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-start' }}>
                                <Typography variant="body1">
                                    {industry}
                                </Typography>
                                <Typography variant="caption" color="text.secondary">
                                    {INDUSTRY_DESCRIPTIONS[industry]}
                                </Typography>
                            </Box>
                        </MenuItem>
                    ))}
                </Select>
                {getFieldError('primaryUserIndustry') && (
                    <FormHelperText>{getFieldError('primaryUserIndustry')}</FormHelperText>
                )}
            </FormControl>
    
            {formData.primaryUserIndustry === 'Other' && (
                <FormControl fullWidth error={!!errors.otherIndustry} sx={{ mb: 3 }} disabled={isLoading}>
                    <TextField
                        label="Please specify your industry"
                        value={formData.otherIndustry}
                        onChange={handleOtherIndustryChange}
                        onBlur={handleBlur('otherIndustry')}
                        error={!!errors.otherIndustry}
                        helperText={errors.otherIndustry || "Enter the industry that best describes your company"}
                        placeholder="e.g., Manufacturing, Healthcare, Education"
                    />
                </FormControl>
            )}
        </Box>
    );
};

export default React.memo(IndustryStep);