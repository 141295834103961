import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, FormControl, TextField, Typography, Button, CircularProgress } from '@mui/material';

const ProductSummaryStep = ({ data, onChange, isLoading }) => {
  const [formData, setFormData] = useState({
    url: data?.url || '',
    summary: data?.summary || ''
  });

  const [touched, setTouched] = useState({
    url: false,
    summary: false
  });

  const [errors, setErrors] = useState({
    url: '',
    summary: ''
  });

  const [isGenerating, setIsGenerating] = useState(false);
  const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
  const mountedRef = useRef(true);

  useEffect(() => {
    return () => {
      mountedRef.current = false;
    };
  }, []);

  useEffect(() => {
    if (data) {
      setFormData({
        url: data.url || '',
        summary: data.summary || ''
      });
      setTouched({
        url: false,
        summary: false
      });
      setErrors({
        url: '',
        summary: ''
      });
    }
  }, [data]);

  const validateForm = useCallback(() => {
    if (!mountedRef.current) return false;

    const newErrors = {
      url: !formData.url ? 'Please enter your company URL' : '',
      summary: !formData.summary ? 'Please provide a product summary' : ''
    };
    setErrors(newErrors);
    return !Object.values(newErrors).some(error => error);
  }, [formData]);

  const transformAndUpdateData = useCallback(() => {
    if (!mountedRef.current || !Object.values(touched).some(t => t)) return;

    if (validateForm()) {
      // Only call onChange if the values have changed
      const newSummary = formData.summary.trim();
      const newUrl = formData.url.trim();

      if (newSummary !== data?.summary || newUrl !== data?.url) {
        const transformedData = {
          summary: newSummary,
          url: newUrl
        };
        onChange(transformedData);
      }
    }
  }, [formData, onChange, validateForm, data, touched]);

  useEffect(() => {
    if (!Object.values(touched).some(t => t)) return;

    const timeOutId = setTimeout(() => {
      if (mountedRef.current) {
        transformAndUpdateData();
      }
    }, 500);

    return () => clearTimeout(timeOutId);
  }, [touched, transformAndUpdateData, formData]);

  const handleUrlChange = useCallback((event) => {
    const value = event.target.value;
    setFormData(prev => ({
      ...prev,
      url: value
    }));
    setTouched(prev => ({ ...prev, url: true }));
  }, []);

  const handleSummaryChange = useCallback((event) => {
    const value = event.target.value;
    setFormData(prev => ({
      ...prev,
      summary: value
    }));
    setTouched(prev => ({ ...prev, summary: true }));
  }, []);

  const handleBlur = useCallback((field) => () => {
    if (!mountedRef.current) return;
    setTouched(prev => ({ ...prev, [field]: true }));
  }, []);

  const handleGenerateSummary = useCallback(async () => {
    if (!formData.url) {
      setErrors(prev => ({ ...prev, url: 'Please enter your company URL before generating summary' }));
      return;
    }

    setIsGenerating(true);
    setErrors({ url: '', summary: '' });

    try {
      const response = await fetch(`${apiBaseUrl}/api/scrape/scrape-summary`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'ngrok-skip-browser-warning': 'true',
        },
        body: JSON.stringify({ url: formData.url }),
      });

      if (!mountedRef.current) return;

      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || 'Failed to generate product summary');
      }

      const responseData = await response.json();

      if (!mountedRef.current) return;

      setFormData(prev => ({ ...prev, summary: responseData.summary }));
      setTouched(prev => ({ ...prev, summary: true }));
    } catch (error) {
      if (!mountedRef.current) return;

      console.error('Error generating product summary:', error);
      setErrors(prev => ({
        ...prev,
        summary: 'Failed to generate summary. Please try again or enter manually.'
      }));
    } finally {
      if (mountedRef.current) {
        setIsGenerating(false);
      }
    }
  }, [formData.url, apiBaseUrl]);

  const getFieldError = useCallback(
    (field) => touched[field] ? errors[field] : '',
    [touched, errors]
  );

  return (
    <Box sx={{ py: 2 }}>
      <Typography variant="h6" gutterBottom>
        Tell us about your product
      </Typography>

      <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
        Enter your company's website URL and we'll generate a product summary, or write your own summary below.
      </Typography>

      <FormControl fullWidth error={!!getFieldError('url')} sx={{ mb: 3 }}>
        <TextField
          label="Company URL"
          value={formData.url}
          onChange={handleUrlChange}
          onBlur={handleBlur('url')}
          error={!!getFieldError('url')}
          helperText={getFieldError('url')}
          placeholder="e.g., https://www.example.com"
          disabled={isLoading}
        />
      </FormControl>

      <Button
        onClick={handleGenerateSummary}
        variant="contained"
        color="primary"
        disabled={isGenerating || isLoading || !formData.url}
        sx={{ mb: 3 }}
      >
        {isGenerating ? <CircularProgress size={24} /> : 'Generate Summary'}
      </Button>

      <FormControl fullWidth error={!!getFieldError('summary')}>
        <TextField
          label="Product Summary"
          value={formData.summary}
          onChange={handleSummaryChange}
          onBlur={handleBlur('summary')}
          error={!!getFieldError('summary')}
          helperText={getFieldError('summary') || "Write a brief summary of your product or service"}
          multiline
          rows={6}
          placeholder="Enter a description of your product or service..."
          disabled={isLoading}
        />
      </FormControl>

      {formData.summary && (
        <Typography variant="body2" color="success.main" sx={{ mt: 2 }}>
          Summary ready for review ✓
        </Typography>
      )}
    </Box>
  );
};

export default React.memo(ProductSummaryStep);