import React, { useState, useEffect, useCallback, useRef } from 'react';
import { Box, FormControl, InputLabel, Select, MenuItem, Chip, FormHelperText, Typography, OutlinedInput, Checkbox, ListItemText, RadioGroup, FormControlLabel, Radio } from '@mui/material';
import { PROFILE_CONSTANTS } from '../../shared/constants/profileConstants';

const GeographicFocusStep = ({ data, onChange, isLoading }) => {
    const mountedRef = useRef(true);
    const [formData, setFormData] = useState({
        geographicFocus: data?.geographicFocus || null,
        regions: data?.regions || [],
        states: data?.states || []
    });

    const [touched, setTouched] = useState({
        geographicFocus: false,
        regions: false,
        states: false
    });

    const [errors, setErrors] = useState({
        geographicFocus: '',
        regions: '',
        states: ''
    });

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    const validateForm = useCallback(() => {
        if (!mountedRef.current) return false;

        const newErrors = {
            geographicFocus: !formData.geographicFocus
                ? 'Please select a geographic focus' 
                : '',
            regions: formData.geographicFocus === 'Regional' && !formData.regions.length
                ? 'Please select at least one region' 
                : '',
            states: formData.geographicFocus === 'State' && !formData.states.length
                ? 'Please select at least one state' 
                : '',
        };

        setErrors(newErrors);
        const isValid = !Object.values(newErrors).some(error => error);
        return isValid;
    }, [formData]);

    useEffect(() => {
        // Validate on mount for new profiles
        if (!data) {
            validateForm();
        }
    }, [data, validateForm]);

    const transformAndUpdateData = useCallback(() => {
        if (!mountedRef.current || !Object.values(touched).some(t => t)) return;

        if (validateForm()) {
            const transformedData = {
                geographicFocus: formData.geographicFocus,
                regions: formData.geographicFocus === 'Regional' ? formData.regions : [],
                states: formData.geographicFocus === 'State' ? formData.states : []
            };
            onChange(transformedData);
        }
    }, [formData, onChange, validateForm, touched]);

    useEffect(() => {
        if (!Object.values(touched).some(t => t)) return;

        const timeoutId = setTimeout(() => {
            if (mountedRef.current) {
                transformAndUpdateData();
            }
        }, 500);

        return () => clearTimeout(timeoutId);
    }, [touched, transformAndUpdateData]);

    const handleFocusChange = useCallback((event) => {
        const value = event.target.value;
        setFormData(prev => {
            const newData = {
                ...prev,
                geographicFocus: value,
                regions: value === 'Regional' ? prev.regions : [],
                states: value === 'State' ? prev.states : []
            };
            return newData;
        });
        setTouched(prev => ({
            ...prev,
            geographicFocus: true,
            regions: value === 'Regional' ? true: false,
            states: value === 'State' ? true : false    
        }));
    }, []);

    const handleRegionsChange = useCallback((event) => {
        const value = event.target.value;
        setFormData(prev => ({
            ...prev,
            regions: value
        }));
        setTouched(prev => ({
            ...prev,
            regions: true
        }));
    }, []);

    const handleStatesChange = useCallback((event) => {
        const value = event.target.value;
        setFormData(prev => ({
            ...prev,
            states: value
        }));
        setTouched(prev => ({
            ...prev,
            states: true
        }));
    }, []);

    const handleBlur = useCallback((field) => () => {
        if (!mountedRef.current) return;
        setTouched(prev => ({ ...prev, [field]: true }));
    }, []);

    const getFieldError = useCallback(
        (field) => touched[field] ? errors[field] : '',
        [touched, errors]
    );

    const renderRegionSelect = useCallback(() => (
        <FormControl
            fullWidth
            error={!!getFieldError('regions')}
            disabled={isLoading}
        >
            <InputLabel>Select Regions</InputLabel>
            <Select
                multiple
                value={formData.regions}
                onChange={handleRegionsChange}
                onBlur={handleBlur('regions')}
                input={<OutlinedInput label="Select Regions" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                    </Box>
                )}
            >
                {PROFILE_CONSTANTS.REGIONS.map((region) => (
                    <MenuItem key={region} value={region}>
                        <Checkbox checked={formData.regions.includes(region)} />
                        <ListItemText primary={region} />
                    </MenuItem>
                ))}
            </Select>
            {getFieldError('regions') && (
                <FormHelperText>{getFieldError('regions')}</FormHelperText>
            )}
        </FormControl>
    ), [formData.regions, handleRegionsChange, handleBlur, getFieldError, isLoading]);

    const renderStateSelect = useCallback(() => (
        <FormControl
            fullWidth
            error={!!getFieldError('states')}
            disabled={isLoading}
        >
            <InputLabel>Select States</InputLabel>
            <Select
                multiple
                value={formData.states}
                onChange={handleStatesChange}
                onBlur={handleBlur('states')}
                input={<OutlinedInput label="Select States" />}
                renderValue={(selected) => (
                    <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                        {selected.map((value) => (
                            <Chip key={value} label={value} />
                        ))}
                    </Box>
                )}
                MenuProps={{
                    PaperProps: {
                        style: {
                            maxHeight: 250,
                            overflow: 'auto'
                        }
                    },
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'left'
                    },
                    transformOrigin: {
                        vertical: 'top',
                        horizontal: 'left'
                    }
                }}
            >
                {PROFILE_CONSTANTS.STATES.map((state) => (
                    <MenuItem key={state} value={state}>
                        <Checkbox checked={formData.states.includes(state)} />
                        <ListItemText primary={state} />
                    </MenuItem>
                ))}
            </Select>
            {getFieldError('states') && (
                <FormHelperText>{getFieldError('states')}</FormHelperText>
            )}
        </FormControl>
    ), [formData.states, handleStatesChange, handleBlur, getFieldError, isLoading]);

    return (
        <Box sx={{ py: 2 }}>
            <Typography variant="h6" gutterBottom>
                Define Your Geographic Focus
            </Typography>

            <Typography variant="body2" color="text.secondary" sx={{ mb: 3 }}>
                Tell us about your target sales territory.
            </Typography>

            <FormControl 
                fullWidth 
                error={!!getFieldError('geographicFocus')}
                sx={{ mb: 3 }}
                disabled={isLoading}
            >
                <RadioGroup
                    value={formData.geographicFocus || ''}
                    onChange={handleFocusChange}
                >
                    <FormControlLabel 
                        value="None" 
                        control={<Radio />} 
                        label="No specific geographic focus" 
                    />
                    <FormControlLabel 
                        value="Regional" 
                        control={<Radio />} 
                        label="Focus on specific regions" 
                    />
                    <FormControlLabel 
                        value="State" 
                        control={<Radio />} 
                        label="Focus on specific states" 
                    />
                </RadioGroup>
                {getFieldError('geographicFocus') && (
                    <FormHelperText>{getFieldError('geographicFocus')}</FormHelperText>
                )}
            </FormControl>

            {formData.geographicFocus === 'Regional' && renderRegionSelect()}
            {formData.geographicFocus === 'State' && renderStateSelect()}
        </Box>
    );
};

export default GeographicFocusStep;