import React from 'react';
import { Box, Card, CardContent, Typography, Button, LinearProgress } from '@mui/material';

function ProfileSummary({ productSummary, onEditProfile, onEditFullProfile, isLoading, userData }) {
    const isProfileComplete = userData?.profile?.completionStatus?.overallPercentage === 100;

    if (isProfileComplete) {
        return (
            <Card>
                <CardContent>
                    <Typography variant="h6" component="div" gutterBottom>
                        Profile Settings
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        Update your Target Customer or Product Summary
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onEditFullProfile}
                        sx={{ width: '100%' }}
                        disabled={isLoading}
                    >
                        Edit Profile
                    </Button>
                </CardContent>
            </Card>
        );
    }

    return (
        <>
            <Card>
                <CardContent>
                    <Typography variant="h6" component="div" gutterBottom>
                        Product Summary
                    </Typography>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={onEditProfile}
                        sx={{ mb: 2, width: '100%' }}
                        disabled={isLoading}
                    >
                        {productSummary?.summary ? 'Edit Product Summary' : 'Add Product Summary'}
                    </Button>
                    {productSummary?.summary && (
                        <Typography variant="body2" color="text.secondary">
                            {productSummary.summary}
                        </Typography>
                    )}
                </CardContent>
            </Card>

            <Card sx={{ mt: 2 }}>
                <CardContent>
                    <Typography variant="h6" gutterBottom>
                        Profile Completion
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mb: 2 }}>
                        Complete your profile to unlock customized simulations tailored to your industry and target customers.
                    </Typography>
                    <Box sx={{ mb: 2 }}>
                        <LinearProgress
                            variant="determinate"
                            value={userData?.profile?.completionStatus?.overallPercentage || 0}
                        />
                        <Typography variant="caption" sx={{ mt: 0.5, display: 'block' }}>
                            {userData?.profile?.completionStatus?.overallPercentage || 0}% Complete
                        </Typography>
                    </Box>
                </CardContent>
            </Card>
        </>
    );
}

export default ProfileSummary;