import React, { useState, useEffect, useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Card, CardContent, Typography, Grid2 as Grid, Button, Box, CircularProgress } from '@mui/material';
import { useAuthInfo } from '@propelauth/react'
import SimulationTypeModal from './SimulationTypeModal';
import ProductSummaryReviewModal from './ProductSummaryReviewModal';
import PerformanceOverview from './PerformanceOverview';
import DifficultySelectionModal from './DifficultySelectionModal';
import CreditCheckModal from './CreditCheckModal';
import analytics from '../../segmentAnalytics'
import ProfileSetupModal from '../Profile/ProfileSetupModal';
import PersonaProfileSelection from './PersonaProfileSelection';
import ProductSummaryModal from './ProductSummaryModal';

const persistPersona = (persona) => {
    if (!persona) return;
    try {
        localStorage.setItem('currentPersona', JSON.stringify(persona));
    } catch (e) {
        console.error('Error saving Persona to localStorage:', e)
    }
};

const clearPersistedPersona = () => {
    try {
        localStorage.removeItem('currentPersona');
    } catch (e) {
        console.error('Error clearing Persona from localStorage:', e)
    }
};

const NewUserWelcome = ({ onQuickStart, onProfileSetup }) => (
    <Card elevation={3}>
        <CardContent sx={{ p: { xs: 2, md: 4 } }}>
            <Box sx={{ textAlign: 'center', mb: 4 }}>
                <Typography variant="h3" gutterBottom>
                    Welcome to Coachable AI!
                </Typography>
            </Box>

            <Grid
                container
                spacing={4}
                columns={12}
                sx={{
                    width: '100%',
                    '& > .MuiGrid2-root': {
                        display: 'flex',
                        width: {
                            xs: '95%',
                            md: '48%'
                        }
                    }
                }}
            >
                <Grid xs={12} md={6}>
                    <Card
                        elevation={2}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            '&:hover': {
                                boxShadow: 6,
                                transform: 'translateY(-4px)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <CardContent sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            p: 3,
                            width: 'auto',
                            boxSizing: 'border-box'
                        }}>
                            <Typography variant="h5" gutterBottom>
                                Quick Start
                            </Typography>
                            <Box sx={{
                                flex: 1,
                                width: '100%',
                                minWidth: 0
                            }}>
                                <Typography variant="body1" color="text.secondary">
                                    Start practicing right away with an AI-powered sales simulation.
                                </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={onQuickStart}
                                sx={{ mt: 3 }}
                                fullWidth
                            >
                                Start Now
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>

                <Grid xs={12} md={6}>
                    <Card
                        elevation={2}
                        sx={{
                            width: '100%',
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden',
                            '&:hover': {
                                boxShadow: 6,
                                transform: 'translateY(-4px)',
                            },
                            transition: 'all 0.3s ease',
                        }}
                    >
                        <CardContent sx={{
                            display: 'flex',
                            flexDirection: 'column',
                            height: '100%',
                            p: 3,
                            width: 'auto',
                            boxSizing: 'border-box'
                        }}>
                            <Typography variant="h5" gutterBottom>
                                Customized Experience
                            </Typography>
                            <Box sx={{ flex: 1, width: '100%', minWidth: 0 }}>
                                <Typography variant="body1" color="text.secondary">
                                    Set up your profile for personalized, industry-specific simulations.
                                </Typography>
                            </Box>
                            <Button
                                variant="contained"
                                size="large"
                                onClick={onProfileSetup}
                                color="secondary"
                                sx={{ mt: 3 }}
                                fullWidth
                            >
                                Customize
                            </Button>
                        </CardContent>
                    </Card>
                </Grid>
            </Grid>
        </CardContent>
    </Card>
);

function MainContent({ onPersonaGenerationStateChange, dashboardData, isGeneratingPersona, onEditProfile, productSummary, onSaveSummary, onSaveProfileSection, isUpdating, userData, onOpenUpgradeModal, profileSaving, onProfileSetup, profileSetupModalOpen, onProfileSetupClose }) {
    const authInfo = useAuthInfo();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;
    const [simulationTypeModalOpen, setSimulationTypeModalOpen] = useState(false);
    const [selectedSimulationType, setSelectedSimulationType] = useState(null);
    const [productSummaryReviewModalOpen, setProductSummaryReviewModalOpen] = useState(false);
    const [difficultyModalOpen, setDifficultyModalOpen] = useState(false);
    const [selectedDifficulty, setSelectedDifficulty] = useState(null);
    const [creditCheckModalOpen, setCreditCheckModalOpen] = useState(false);
    const [isNewUser, setIsNewUser] = useState(true);
    const [quickStartProductSummaryModalOpen, setQuickStartProductSummaryModalOpen] = useState(false);
    const [personaProfileModalOpen, setPersonaProfileModalOpen] = useState(false);
    const [personaSelections, setPersonaSelections] = useState(null);
    const navigate = useNavigate();

    useEffect(() => {
        if (dashboardData?.completedSimulations?.length > 0) {
            setIsNewUser(false);
        }
    }, [dashboardData]);

    const handleQuickStart = () => {
        analytics.track('Quick Start Selected', {
            startSource: 'Dashboard'
        });

        if (!productSummary?.summary) {
            setQuickStartProductSummaryModalOpen(true);
        } else {
            handleStartSimulation('quick');
        }
    };
    
    const handleStartSimulation = useCallback((type) => {
        if (userData && (userData.credits !== undefined || userData.plan)) {
            if (userData.plan === 'Unlimited' || (userData.credits && userData.credits > 0)) {
                analytics.track('New Simulation Flow Started', {
                    startSource: 'Dashboard',
                    simulationType: type
                });

                if (type === 'customized') {
                    setPersonaProfileModalOpen(true);
                } else {
                    setSimulationTypeModalOpen(true);
                }
            } else {
                analytics.track('Out of Credits Warning Viewed');
                setCreditCheckModalOpen(true);
            }
        } else {
            analytics.track('New Simulation Flow Started', {
                startSource: 'Dashboard',
                simulationType: type
            });
            if (type === 'customized') {
                setPersonaProfileModalOpen(true);
            } else {
                setSimulationTypeModalOpen(true);
            }
        }
    }, [userData]);

    const handleSimulationTypeSelection = (type) => {
        analytics.track('Simulation Type Selected', {
            simulationType: type,
        });
        setSelectedSimulationType(type);
        setSimulationTypeModalOpen(false);
        setDifficultyModalOpen(true);
    };

    const handleDifficultySelection = (difficulty) => {
        analytics.track('Difficulty Level Selected', {
            difficultyLevel: difficulty,
        });
        setSelectedDifficulty(difficulty);
        setDifficultyModalOpen(false);
        setProductSummaryReviewModalOpen(true);
    }

    const handleBackToSimulationType = () => {
        setDifficultyModalOpen(false);
        setSimulationTypeModalOpen(true);
    };

    const handleBackToDifficultySelection = () => {
        setProductSummaryReviewModalOpen(false);
        setDifficultyModalOpen(true);
    };

    const handlePersonaProfileSubmit = useCallback((selections) => {
        analytics.track('Persona Profile Selected', {
            selections: {
                targetIndustry: selections.targetIndustry,
                companySize: selections.companySize,
                decisionMakerLevels: selections.decisionMakerLevels,
                geographicFocus: selections.geographicFocus.type
            }
        });
        setPersonaSelections(selections);
        setPersonaProfileModalOpen(false);
        setSimulationTypeModalOpen(true);
    }, []);

    const handleProfileComplete = useCallback(() => {
        analytics.track('Profile Setup Completed');
        if (document.activeElement instanceof HTMLElement) {
            document.activeElement.blur();
        }
        onProfileSetupClose?.();
        setTimeout(() => {
            handleStartSimulation('customized');
        }, 300);
    }, [handleStartSimulation, onProfileSetupClose]);

    const handleProductSummaryReviewConfirm = async (summaryData) => {
        clearPersistedPersona();
        analytics.track('Persona Generation Started');

        try {
            onPersonaGenerationStateChange(true);
            await onSaveSummary(summaryData);
            const simulationGender = Math.random() < 0.5 ? 'male' : 'female';

            const requestPayload = {
                summary: summaryData.summary,
                url: summaryData.url,
                propelAuthId: authInfo.user.userId,
                simulationGender: simulationGender,
                simulationType: selectedSimulationType,
                difficulty: selectedDifficulty,
                userEmail: authInfo.user.email,
                personaType: 'initial',
                userIndustry: userData.profile.userIndustry,
                ...(personaSelections && {
                    method: 'customized',
                    selections: personaSelections
                })
            };

            const response = await fetch(`${apiBaseUrl}/api/persona/generate`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true',
                },
                body: JSON.stringify(requestPayload),
            });

            if (!response.ok) {
                throw new Error('Failed to generate persona');
            }
            const data = await response.json();
            if (personaSelections) {
                data.customizationDetails = {
                    userIndustry: userData.profile.userIndustry.primaryUserIndustry,
                    targetIndustry: personaSelections.targetIndustry,
                    targetSubIndustry: personaSelections.targetSubIndustry,
                    companySize: personaSelections.companySize,
                    decisionMakerLevel: personaSelections.decisionMakerLevel,
                    decisionMakerDepartment: personaSelections.decisionMakerDepartment,
                    geographicFocus: personaSelections.geographicFocus
                }
            }
            persistPersona(data);

            setProductSummaryReviewModalOpen(false);
            onPersonaGenerationStateChange(false);
            navigate('/persona-display', { state: {persona: data, simulationType: selectedSimulationType, difficulty: selectedDifficulty, summary: summaryData.summary, userPlan: userData?.plan || 'Trial' } });
        } catch (error) {
            console.error('Error generating persona:', error);
            analytics.track('Persona Generation Failed', { error: error.message });
            onPersonaGenerationStateChange(false);
            setProductSummaryReviewModalOpen(false);
            await new Promise(resolve => setTimeout(resolve, 100));
            navigate('/', { 
                replace: true,
                state: { 
                    error: 'Failed to generate persona. Please try again.' 
                } 
            });
        }
    };

    if (isUpdating) {
        return (
            <Box display="flex" justifyContent="center" alignItems="center" height="100">
                <CircularProgress />
            </Box>
        );
    }

    const renderHeaderButtons = () => {
        const isProfileCompleted = userData.profile.completionStatus.overallPercentage === 100;

        return (
            <Box sx={{
                display: 'flex',
                gap: 2,
                flexWrap: 'wrap',
            }}>
                <Button
                    variant="contained"
                    color="primary"
                    size="medium"
                    onClick={() => handleStartSimulation('quick')}
                >
                    Quick Start Simulation
                </Button>
                <Button
                    variant="contained"
                    color="secondary"
                    size="medium"
                    onClick={() => isProfileCompleted
                        ? handleStartSimulation('customized')
                        : handleProfileSetup()
                    }
                >
                    {isProfileCompleted
                        ? 'Start Customized Simulation'
                        : 'Unlock Customized Simulations'}
                </Button>
            </Box>
        );
    };

    const handleProfileSetup = () => {
        analytics.track('Profile Setup Started');
        onProfileSetup?.();
    };

    const handleProfileSectionSave = async (section, data) => {
        try {
            const result = await onSaveProfileSection(section, data, { closeModal: false });
            analytics.track('Profile Section Saved', { section });
            return result;
        } catch (error) {
            console.error(`Error saving ${section}:`, error);
            throw error;
        } 
    };

    return (
        <>
            {isNewUser ? (
                <NewUserWelcome
                    onQuickStart={handleQuickStart}
                    onProfileSetup={handleProfileSetup}
                />
            ) : (
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        gap: 3,
                        height: '100%',
                        flex: 1
                    }}
                >
                    <Card
                        elevation={1}
                        sx={{
                            width: '100%',
                        }}
                    >
                        <CardContent>
                            <Box
                                sx={{
                                    display: 'flex',
                                    alignItems: 'center',
                                    flexWrap: 'wrap',
                                    gap: 3
                                }}
                            >
                                <Typography 
                                    variant="h4"
                                    component="div"
                                    sx={{
                                        fontSize: { xs: '1.5rem', sm: '2rem' },
                                    }}
                                >
                                    Welcome back, {authInfo.user.firstName}!
                                </Typography>
                                {renderHeaderButtons()}
                            </Box>
                        </CardContent>
                    </Card>

                    <Card
                        elevation={3}
                        sx={{
                            flex: 1,
                            display: 'flex',
                            flexDirection: 'column',
                            overflow: 'hidden'
                        }}
                    >
                        <CardContent 
                            sx={{
                                flex: 1,
                                overflow: 'auto',
                                display: 'flex',
                                flexDirection: 'column'
                            }}
                        >
                            <PerformanceOverview
                                data={dashboardData?.completedSimulations}
                                sx={{
                                    flex: 1,
                                    display: 'flex',
                                    flexDirection: 'column' 
                                }}
                            />
                        </CardContent>
                    </Card>
                </Box>
            )}
            <CreditCheckModal
                open={creditCheckModalOpen}
                onClose={() => setCreditCheckModalOpen(false)}
                onUpgrade={onOpenUpgradeModal}
            />
            <SimulationTypeModal
                open={simulationTypeModalOpen}
                onClose={() => setSimulationTypeModalOpen(false)}
                onSelect={handleSimulationTypeSelection}
            />
            <DifficultySelectionModal
                open={difficultyModalOpen}
                onClose={() => setDifficultyModalOpen(false)}
                onSelect={handleDifficultySelection}
                onBack={handleBackToSimulationType}
            />
            <ProductSummaryModal
                open={quickStartProductSummaryModalOpen}
                onClose={() => setQuickStartProductSummaryModalOpen(false)}
                onSave={async (summaryData) => {
                    await onSaveSummary(summaryData);
                    setQuickStartProductSummaryModalOpen(false);
                    handleStartSimulation('quick');
                }}
                existingSummary={productSummary}
            />
            <ProductSummaryReviewModal
                open={productSummaryReviewModalOpen}
                onClose={() => setProductSummaryReviewModalOpen(false)}
                onConfirm={handleProductSummaryReviewConfirm}
                productSummary={productSummary}
                onSaveSummary={onSaveSummary}
                onBack={handleBackToDifficultySelection}
                isLoading={isUpdating}
            />
            <ProfileSetupModal
                open={profileSetupModalOpen}
                onClose={onProfileSetupClose}
                existingProfile={userData}
                onSaveProgress={handleProfileSectionSave}
                isLoading={profileSaving}
                onComplete={handleProfileComplete}
            />
            {personaProfileModalOpen && (
                <PersonaProfileSelection
                    open={personaProfileModalOpen}
                    onClose={() => setPersonaProfileModalOpen(false)}
                    onSubmit={handlePersonaProfileSubmit}
                    userProfile={userData}
                    isLoading={isUpdating}
                />
            )}
        </>
    );
}

export default React.memo(MainContent);