import React, { useState, useEffect, useCallback } from 'react';
import { CircularProgress, Box, Snackbar, Alert } from "@mui/material";
import { useLocation } from 'react-router-dom'
import { useAuthInfo } from '@propelauth/react'
import Header from './Header';
import RecentSimulations from './RecentSimulations'
import MainContent from './MainContent';
import ProfileSummary from './ProfileSummary';
import ProductSummaryModal from './ProductSummaryModal';
import LoadingScreen from '../Simulation/LoadingScreen';
import UpgradeModal from './UpgradeModal';
import analytics from '../../segmentAnalytics';

function Dashboard() {
    const location = useLocation();
    const [error, setError] = useState(null);
    const [isGeneratingPersona, setIsGeneratingPersona] = useState(false);
    const [dashboardData, setDashboardData] = useState(null);
    const [isInitialLoading, setIsInitialLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [productSummary, setProductSummary] = useState(null);
    const [productSummaryModalOpen, setProductSummaryModalOpen] = useState(false);
    const [userData, setUserData] = useState(null);
    const [upgradeModalOpen, setUpgradeModalOpen] = useState(false);
    const [purchaseSuccessOpen, setPurchaseSuccessOpen] = useState(false);
    const [profileSaving, setProfileSaving] = useState(false);
    const [profileSetupModalOpen, setProfileSetupModalOpen] = useState(false);
    const authInfo = useAuthInfo();
    const apiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const fetchDashboardData = useCallback(async () => {
        setIsInitialLoading(true);
        try {
            const [dashboardResponse, summaryResponse, userDataResponse ] = await Promise.all([
                fetch(`${apiBaseUrl}/api/database/dashboard-data/${authInfo.user.userId}`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true',
                    }
                }),
                fetch (`${apiBaseUrl}/api/product-summary/${authInfo.user.userId}`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true',
                    }
                }),
                fetch(`${apiBaseUrl}/api/database/user/${authInfo.user.userId}`, {
                    headers: {
                        'ngrok-skip-browser-warning': 'true',
                    }
                }),
            ]);

            if (!dashboardResponse.ok || !summaryResponse.ok || !userDataResponse.ok) {
                throw new Error('Failed to fetch data');
            }

            const dashboardData = await dashboardResponse.json();
            const summaryData = await summaryResponse.json();
            const userData = await userDataResponse.json();

            setDashboardData(dashboardData);
            setProductSummary(summaryData);
            setUserData(userData);
        } catch (error) {
            console.error('Error fetching dashboard data:', error);
        } finally {
            setIsInitialLoading(false);
        }
    }, [authInfo.user.userId, apiBaseUrl]);

    useEffect(() => {
        fetchDashboardData();
    }, [fetchDashboardData]);

    useEffect(() => {
        if (location.state?.error) {
            setError(location.state.error);
            window.history.replaceState({}, document.title);
        }
    }, [location]);

    useEffect(() => {
        if (location.state?.purchaseSuccess) {
            setPurchaseSuccessOpen(true);
            fetchDashboardData();
            window.history.replaceState({}, document.title);
        }
    }, [location, fetchDashboardData]);

    const handlePurchaseSuccessClose = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setPurchaseSuccessOpen(false);
    };

    const handleCloseError = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setError(null);
    };

    const handleEditProfile = () => {
        setProductSummaryModalOpen(true);
    };

    const handleProfileSetup = () => {
        analytics.track('Profile Setup Started');
        setProfileSetupModalOpen(true);
    };

    const handleOpenUpgradeModal = useCallback(() => {
        setUpgradeModalOpen(true);
    }, []);
    
    const handleSaveSummary = useCallback(async (summaryData, options = { closeModal: true }) => {
        setIsUpdating(true);
        try {
            const response = await fetch(`${apiBaseUrl}/api/product-summary/${authInfo.user.userId}`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true',
                },
                body: JSON.stringify(summaryData),
            });
            if (!response.ok) {
                throw new Error('Failed to save product summary');
            }
            const data = await response.json();
            setProductSummary(data);
            if (options.closeModal) {
                setProductSummaryModalOpen(false);
            }
            return data;
        } catch (error) {
            console.error('Error saving product summary:', error);
            throw error;
        } finally {
            setIsUpdating(false);
            if (options.closeModal) {
                setProductSummaryModalOpen(false);
            }
        }
    }, [authInfo.user.userId, apiBaseUrl]);

    const handleSaveProfileSection = async (section, data) => {
        setProfileSaving(true);
        try {
            validateSectionData(section, data);
            const transformedData = transformSectionDataForApi(section, data);
            const response = await fetch(`${apiBaseUrl}/api/profile/${authInfo.user.userId}/${section}`, {
                method: 'PUT',
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true',
                },
                body: JSON.stringify(transformedData),
            });

            if (!response.ok) {
                const errorData = await response.json();
                throw new Error(errorData.message || `Failed to save ${section}`);
            }

            const updatedProfile = await response.json();
            // Update appropriate state based on section
            updateProfileState(section, updatedProfile);

            return updatedProfile;
        } catch (error) {
            console.error(`Error saving ${section}:`, error);
            setError(error.message);
            throw error;
        } finally {
            setProfileSaving(false);
        }
    };

    const updateProfileState = (section, updatedProfile) => {
        switch (section) {
            case 'productSummary':
                setProductSummary(updatedProfile.productSummary);
                setUserData(prev => {
                    if (!prev) {
                        return {
                            profile: {
                                completionStatus: updatedProfile.completionStatus,
                                userIndustry: {
                                    primaryUserIndustry: null,
                                    userSubIndustry: '',
                                    otherIndustry: ''
                                },
                                targetCustomer: {
                                    companySizes: [],
                                    decisionMakerLevels: [],
                                    decisionMakerDepartments: [],
                                    targetIndustries: [],
                                    geographicFocus: {
                                        geographicFocus: null,
                                        regions: [],
                                        states: []
                                    }
                                }
                            },
                            productSummary: updatedProfile.productSummary,
                        };
                    }
                    return {
                        ...prev,
                        productSummary: updatedProfile.productSummary,
                        profile: {
                            ...prev.profile,
                            completionStatus: updatedProfile.completionStatus
                        }
                    };
                });
                break;

            case 'userIndustry':
                setUserData(prev => ({
                    ...prev,
                    profile: {
                        ...prev.profile,
                        userIndustry: updatedProfile.profile.userIndustry,
                        completionStatus: updatedProfile.completionStatus
                    }
                }));
                break;
            
            case 'targetCustomer':
                setUserData(prev => ({
                    ...prev,
                    profile: {
                        ...prev.profile,
                        targetCustomer: updatedProfile.profile.targetCustomer,
                        completionStatus: updatedProfile.completionStatus
                    }
                }));
                break;
            
            case 'targetCustomer.geographicFocus':
                setUserData(prev => ({
                    ...prev,
                    profile: {
                        ...prev.profile,
                        targetCustomer: {
                            ...prev.profile.targetCustomer,
                            geographicFocus: updatedProfile.profile.targetCustomer.geographicFocus
                        },
                        completionStatus: updatedProfile.completionStatus
                    }
                }));
                break;

            default:
                console.warn(`Unhandled profile section: ${section}`);
        }
    };

    const transformSectionDataForApi = (section, data) => {
        switch (section) {
            case 'productSummary':
                return {
                    summary: data.summary?.trim() || '',
                    url: data.url?.trim() || ''
                };

            case 'userIndustry':
                return {
                    primaryUserIndustry: data.primaryUserIndustry,
                    userSubIndustry: data.userSubIndustry || '',
                    otherIndustry: data.primaryUserIndustry === 'Other' 
                        ? data.otherIndustry?.trim() 
                        : ''
                };

            case 'targetCustomer':
                return {
                    companySizes: data.companySizes || [],
                    decisionMakerLevels: data.decisionMakerLevels || [], // Transform from frontend naming
                    decisionMakerDepartments: data.decisionMakerDepartments || [],
                    targetIndustries: data.targetIndustries?.map(industry => ({
                        targetIndustry: industry.targetIndustry,
                        targetSubIndustries: industry.targetSubIndustries || []
                    })) || [],
                    geographicFocus: {
                        geographicFocus: data.geographicFocus?.geographicFocus ?? null,
                        regions: data.geographicFocus?.regions || [],
                        states: data.geographicFocus?.states || []
                    }
                };
            case 'targetCustomer.geographicFocus':
                const geoFocus = data.geographicFocus || data;
                return {
                    targetCustomer: {
                        geographicFocus: {
                            geographicFocus: geoFocus.geographicFocus ?? null,
                            regions: geoFocus.regions || [],
                            states: geoFocus.states || []
                        }
                    }
                };

            default:
                return data;
        }
    };

    const validateSectionData = (section, data) => {
        switch (section) {
            case 'productSummary':
                if (!data.summary?.trim()) {
                    throw new Error('Product summary is required');
                }
                return true;
    
            case 'userIndustry':
                if (!data.primaryUserIndustry) {
                    throw new Error('Primary industry is required');
                }
                if (data.primaryUserIndustry === 'Other' && !data.otherIndustry?.trim()) {
                    throw new Error('Other industry description is required');
                }
                return true;
    
            case 'targetCustomer':
                if (!data.companySizes?.length) {
                    throw new Error('At least one company size must be selected');
                }
                if (!data.decisionMakerLevels?.length) {
                    throw new Error('At least one decision maker level must be selected');
                }
                if (!data.targetIndustries?.length) {
                    throw new Error('At least one target industry must be selected');
                }
                
                const needsDepartments = data.decisionMakerLevels.some(
                    level => !['Startup Founder', 'Business Owner'].includes(level)
                );
                
                if (needsDepartments && !data.decisionMakerDepartments?.length) {
                    throw new Error('Decision maker departments are required for selected levels');
                }
                return true;
            
            case 'targetCustomer.geographicFocus':
                if (!data.geographicFocus) {
                    throw new Error('Geographic focus slection is required');
                }
                if (data.geographicFocus === 'Regional' && !data.regions?.length) {
                    throw new Error('At least one region must be selected');
                }
                if (data.geographicFocus === 'State' && !data.states?.length) {
                    throw new Error('At least one state must be selected');
                }
                return true;
    
            default:
                return true;
        }
    };

    const handlePersonaGenerationStateChange = (isGenerating) => {
        setIsGeneratingPersona(isGenerating);
        setIsInitialLoading(isGenerating);
    };

    return (
        <Box
            sx={{
                flexGrow: 1,
                minHeight: '100vh',
                display: 'flex',
                flexDirection: 'column',
                backgroundColor: 'background.default',
            }}
        >
            <Header 
                dashboardData={dashboardData} 
                userData={userData} 
                onOpenUpgradeModal={handleOpenUpgradeModal}
            />
            {isGeneratingPersona ? (
                <LoadingScreen />
            ) : isInitialLoading ? (
                <Box display="flex" justifyContent="center" alignItems="center" height="100vh">
                    <CircularProgress />
                </Box>
            ) : (
                <Box 
                    sx={{
                        p: 3,
                        flex: 1,
                        display: 'flex',
                    }}
                >
                    {dashboardData?.completedSimulations?.length > 0 ? (
                        <Box
                            sx={{
                                width: '100%',
                                height: '100%',
                                display: 'flex',
                                gap: 3
                            }}
                        >
                            <Box
                                sx={{
                                    width: '20%',
                                    display: 'flex',
                                    flexDirection: 'column'
                                }}
                            >
                                <RecentSimulations
                                    data={dashboardData?.completedSimulations}
                                    isLoading={isGeneratingPersona || isInitialLoading}
                                />
                            </Box>

                            <Box
                                sx={{
                                    width: '60%',
                                    display: 'flex',
                                    flexDirection: 'column',
                                    gap: 3
                                }}
                            >
                                <MainContent
                                    onPersonaGenerationStateChange={handlePersonaGenerationStateChange}
                                    dashboardData={dashboardData}
                                    isGeneratingPersona={isGeneratingPersona}
                                    onEditProfile={handleEditProfile}
                                    productSummary={productSummary}
                                    onSaveSummary={handleSaveSummary}
                                    onSaveProfileSection={handleSaveProfileSection}
                                    isUpdating={isUpdating}
                                    userData={userData}
                                    onOpenUpgradeModal={handleOpenUpgradeModal}
                                    profileSaving={profileSaving}
                                    onProfileSetup={handleProfileSetup}
                                    profileSetupModalOpen={profileSetupModalOpen}
                                    onProfileSetupClose={() => setProfileSetupModalOpen(false)}
                                />
                            </Box> 

                            <Box
                                sx={{
                                   width: '20%',
                                   display: 'flex',
                                   flexDirection: 'column',
                                   gap: 3
                                }}
                            >
                                <ProfileSummary
                                    productSummary={productSummary}
                                    onEditProfile={handleEditProfile}
                                    onEditFullProfile={handleProfileSetup}
                                    isLoading={isGeneratingPersona || isUpdating}
                                    userData={userData}
                                />
                            </Box>
                        </Box>
                    ) : (
                        <Box
                            sx={{
                                display: 'flex',
                                justifyContent: 'center',
                                width: '100%',
                            }}
                        >
                            <Box sx={{ width: '100%', maxWidth: '1200px' }}>
                                <MainContent
                                    onPersonaGenerationStateChange={handlePersonaGenerationStateChange}
                                    dashboardData={dashboardData}
                                    isGeneratingPersona={isGeneratingPersona}
                                    onEditProfile={handleEditProfile}
                                    productSummary={productSummary}
                                    onSaveSummary={handleSaveSummary}
                                    onSaveProfileSection={handleSaveProfileSection}
                                    isUpdating={isUpdating}
                                    userData={userData}
                                    onOpenUpgradeModal={handleOpenUpgradeModal}
                                    profileSaving={profileSaving}
                                    onProfileSetup={handleProfileSetup}
                                    profileSetupModalOpen={profileSetupModalOpen}
                                    onProfileSetupClose={() => setProfileSetupModalOpen(false)}
                                />
                            </Box>
                        </Box>
                    )}
                </Box>
            )}
            
    
            <Snackbar open={!!error} autoHideDuration={6000} onClose={handleCloseError}>
                <Alert onClose={handleCloseError} severity="warning" sx={{ width: '100%' }}>
                    {error}
                </Alert>
            </Snackbar>
            
            <Snackbar
                open={purchaseSuccessOpen}
                autoHideDuration={4000}
                onClose={handlePurchaseSuccessClose}
                anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
            >
                <Alert onClose={handlePurchaseSuccessClose} severity="success" sx={{ width: '100%' }}>
                    Thank you for your purchase! Your account has been updated.
                </Alert>
            </Snackbar>
    
            <ProductSummaryModal
                open={productSummaryModalOpen}
                onClose={() => setProductSummaryModalOpen(false)}
                onSave={handleSaveSummary}
                existingSummary={productSummary}
            />
    
            <UpgradeModal
                open={upgradeModalOpen}
                onClose={() => setUpgradeModalOpen(false)}
                userId={authInfo.user.userId}
            />
        </Box>
    );
}

export default Dashboard;