import React, { useState, useEffect, useCallback } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { Grid, Box, Paper } from '@mui/material';
import Header from '../Dashboard/Header';
import PersonaInfoPanel from './PersonaInfoPanel';
import PreCallDisplay from './PreCallDisplay';
import MainConversationArea from './MainConversationArea';
import CallInfoPanel from './CallInfoPanel';
import ControlBar from './ControlBar';
import CallEndingScreen from './CallEndingScreen';
import vapi from '../../services/vapiService';
import CountdownDialog from './CountdownDialog';
import HelpDialog from './HelpDialog';
import analytics from '../../segmentAnalytics'
import { generateAssistantConfig } from '../../utils/vapiAssistantConfigGenerator';
import { useAuthInfo } from '@propelauth/react';
import { deductCredit } from '../../services/creditService';

const getPersistedPersona = () => {
    try {
        const saved = localStorage.getItem('currentPersona');
        return saved ? JSON.parse(saved) : null;
    } catch (e) {
        console.error('Error loading Persona from localStorage:', e)
        return null;
    }
};

const clearPersistedPersona = () => {
    try {
        localStorage.removeItem('currentPersona');
    } catch (e) {
        console.error('Error clearing Persona from localStorage:', e)
    }
};

function SimulationPage() {
    const location = useLocation();
    const navigate = useNavigate();
    const authInfo = useAuthInfo();
    const ApiBaseUrl = process.env.REACT_APP_API_BASE_URL;

    const [persona, setPersona] = useState(null);
    const [simulationType, setSimulationType] = useState('discovery-meeting');
    const [difficulty, setDifficulty] = useState('intermediate');
    const [notes, setNotes] = useState('');
    const [summary, setSummary] = useState('');
    const [userPlan, setUserPlan] = useState('Trial');
    const [isCallStarted, setIsCallStarted] = useState(false);
    const [isCallEnding, setIsCallEnding] = useState(false);
    const [isAISpeaking, setIsAISpeaking] = useState(false);
    const [duration, setDuration] = useState(0);
    const [isMuted, setIsMuted] = useState(false);
    const [transcriptData, setTranscriptData] = useState([]);
    const [showCountdown, setShowCountdown] = useState(false);
    const [helpDialogOpen, setHelpDialogOpen] = useState(false);
    const [error, setError] = useState(null);

    useEffect(() => {
        const initializePersona = () => {
            const locationState = location.state || {};
            const persistedPersona = getPersistedPersona();

            if (locationState.persona) {
                setPersona(locationState.persona);
                localStorage.setItem('currentPersona', JSON.stringify(locationState.persona));
            } else if (persistedPersona) {
                setPersona(persistedPersona);
            } else {
                setError('No persona data found');
                navigate('/', {
                    replace: true,
                    state: { error: 'Session expired. Please generate a new persona.'}
                });
                return;
            }

            setSimulationType(locationState.simulationType || 'discovery-meeting');
            setDifficulty(locationState.difficulty || 'intermediate');
            setSummary(locationState.summary || '');
            setUserPlan(locationState.userPlan || 'Trial');
            setNotes(locationState.notes || '');
        };

        initializePersona();
    }, [location, navigate]);
    
    useEffect(() => {
        let interval;
        if (isCallStarted && !isCallEnding) {
            interval = setInterval(() => {
                setDuration(prev => prev + 1);
            }, 1000);
        }
        return () => {
            if (interval) clearInterval(interval);
        };
    }, [isCallStarted, isCallEnding]);

    const fetchWithRetry = async (url, options, retries = 3) => {
        for (let i = 0; i < retries; i++) {
            try {
                const response = await fetch(url, options);
                if (!response.ok) {
                    const errorBody = await response.text();
                    console.error(`Server responded with status ${response.status}. Body: ${errorBody}`);
                    throw new Error(`Failed to fetch: ${response.statusText}`);
                }
                return response;
            } catch (error) {
                if (i === retries - 1) throw error;
                await new Promise(resolve => setTimeout(resolve, 1000));
            }
        }
    };

    const generateScorecard = useCallback(async (transcript) => {
        if (!persona) return;
        try {

            const requestBody = JSON.stringify({
                transcript,
                simulationId: persona.simulationId,
                simulationType,
                userName: authInfo.user.firstName,
                userEmail: authInfo.user.email,
            });

            const response = await fetchWithRetry(`${ApiBaseUrl}/api/analysis/generate-scorecard`, {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'ngrok-skip-browser-warning': 'true',
                },
                body: requestBody,
            });

            return await response.json();
        } catch (error) {
            console.error('Error generating scorecard:', error);
            throw error;
        }
    }, [persona, authInfo.user.email, authInfo.user.firstName, simulationType, ApiBaseUrl]);

    const handleCallEnd = useCallback(async () => {
        if (!persona) return;
        if (isCallEnding) return;
            setIsCallEnding(true);
            setIsCallStarted(false);

            const userMessageCount = transcriptData.filter(entry => entry.speaker === 'You').length;

            try {
                const userMessages = transcriptData.filter(entry => entry.speaker === 'You');
                if (userMessages.length <= 2) {
                    analytics.track('Simulation Ended - Partial', {
                        simulationId: persona.simulationId,
                        simulationType: simulationType,
                        simulationDuration: duration,
                        userMessageCount: userMessageCount
                    });
                    clearPersistedPersona();
                    navigate('/', {
                        state: {
                            error: 'Conversation too short for analysis. Start a new Simulation to continue practicing'
                        }
                    });
                    return;
                }
                const formattedTranscript = transcriptData
                    .map(entry => `${entry.speaker === 'You' ? authInfo.user.firstName : 'Prospect'}: ${entry.text}`)
                    .join('\n');

                analytics.track('Simulation Ended - Full', {
                    simulationId: persona.simulationId,
                    simulationType: simulationType,
                    simulationDuration: duration,
                    userMessageCount: userMessageCount
                });

                await new Promise(resolve => setTimeout(resolve, 1000));
                
                const scorecard = await generateScorecard(formattedTranscript);

                if (userPlan === 'Trial' || userPlan === 'Usage') {
                    try {
                        await deductCredit(authInfo.user.userId);
                    } catch (error) {
                        console.error('Error deducting credit:', error);
                    }
                }
                clearPersistedPersona();
                navigate(`/call-review/${persona.simulationId}`, {
                    state: {
                        scorecard: scorecard,
                        persona: persona
                    }
                });
            } catch (error) {
                console.error('Error ending call:', error);
                let errorMessage;
                if (error.response?.status === 500) {
                    errorMessage = 'Server error while analyzing conversation. Please try again.';
                } else {
                    errorMessage = 'An unexpected error occurred. Please try again.';
                }

                analytics.track('Simulation Error', {
                    simulationId: persona.simulationId,
                    error: errorMessage,
                    errorDetail: error.message
                });
                clearPersistedPersona();
                navigate('/', { state: { error: errorMessage } });
        }
    }, [isCallEnding, persona, generateScorecard, navigate, transcriptData, authInfo.user.firstName, duration, simulationType, userPlan, authInfo.user.userId]);

    useEffect(() => {
        if (!persona) return;
        const handleCallStart = () => setShowCountdown(false)
        const handleSpeechStart = () => setIsAISpeaking(true);
        const handleSpeechEnd = () => setIsAISpeaking(false);
        const handleMessage = (msg) => {
            if (msg.type === 'conversation-update') {
                const newTranscriptData = msg.conversation
                .filter(entry => entry.role === 'user' || entry.role === 'assistant')
                .map(entry => ({
                    speaker: entry.role === 'user' ? 'You' : persona.name,
                    text: entry.content.trim(),
                }));
                setTranscriptData(newTranscriptData);
            }
        };
        const handleError = (error) => console.error("VAPI error:", error);

        vapi.on("call-start", handleCallStart);
        vapi.on("call-end", handleCallEnd);
        vapi.on("speech-start", handleSpeechStart);
        vapi.on("speech-end", handleSpeechEnd);
        vapi.on("message", handleMessage);
        vapi.on("error", handleError);

        return () => {
            vapi.removeListener("call-start", handleCallStart);
            vapi.removeListener("call-end", handleCallEnd);
            vapi.removeListener("speech-start", handleSpeechStart);
            vapi.removeListener("speech-end", handleSpeechEnd);
            vapi.removeListener("message", handleMessage);
            vapi.removeListener("error", handleError);
        };
    }, [persona, handleCallEnd, isCallEnding]);

    const handleStartCall = async () => {
        setIsCallStarted(true);
        setShowCountdown(true);

        analytics.track('Simulation Started', {
            simulationType: simulationType,
            difficulty: difficulty,
            simulationId: persona.simulationId,
        });
        
        const serverUrl = `${ApiBaseUrl}/api/vapi/end-call`;
        const assistantConfig = generateAssistantConfig(persona, simulationType, difficulty, serverUrl, summary, authInfo.user.firstName, authInfo.user.properties.companyName);
        const callMetadata = {
            userName: authInfo.user.firstName,
            userEmail: authInfo.user.email,
            simulationId: persona.simulationId,
            simulationType: simulationType,
        };

        const finalConfig = {
            ...assistantConfig,
            metadata: callMetadata,
        };
        
        try {
            await vapi.start(finalConfig);
        } catch (error) {
            console.error('Error starting call:', error);
            setIsCallStarted(false);
            setShowCountdown(false);
        }
    };

    if (error) {
        return null;
    }

    if (!persona) {
        return null;
    }

    const handleToggleMute = () => {
        const newMuteState = !isMuted;
        vapi.setMuted(newMuteState);
        setIsMuted(newMuteState);
    };

    const handleEndCall = () => {
        vapi.stop();
        handleCallEnd();
    };

    const handleHelp = () => {
        setHelpDialogOpen(true);
    };

    const handleCloseHelp = () => {
        setHelpDialogOpen(false);
    };

    return (
        <Box sx={{ height: '100vh', display: 'flex', flexDirection: 'column', bgcolor: 'background.default' }}>
            <Header />
            <Box sx={{ flexGrow: 1, overflow: 'hidden', p: 3 }}>
                {isCallEnding ? (
                    <CallEndingScreen />
                ) : (
                    <Grid container spacing={3} sx={{ height: '100%' }}>
                        <Grid item xs={12} md={3} sx={{ height: '100%' }}>
                            <Paper elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', p: 2 }}>
                                <PersonaInfoPanel persona={persona} />
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={6} sx={{ height: '100%' }}>
                            <Paper elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column', p: 2 }}>
                                {isCallStarted ? (
                                    <MainConversationArea
                                        persona={persona}
                                        isAISpeaking={isAISpeaking}
                                        transcriptData={transcriptData}
                                    />
                                ) : (
                                    <PreCallDisplay
                                        persona={persona}
                                        simulationType={simulationType}
                                        onStartCall={handleStartCall}
                                        onHelp={handleHelp}
                                    />
                                )}
                            </Paper>
                        </Grid>
                        <Grid item xs={12} md={3} sx={{ height: '100%' }}>
                            <Paper elevation={3} sx={{ height: '100%', display: 'flex', flexDirection: 'column', justifyContent: 'center', p: 2 }}>
                                <CallInfoPanel 
                                    duration={duration} 
                                    notes={notes}
                                    simulationType={simulationType}
                                />
                            </Paper>
                        </Grid>
                    </Grid>
                )}
            </Box>
            {isCallStarted && !isCallEnding && (
                <Paper elevation={3} sx={{ mt: 2 }}>
                    <ControlBar
                        isMuted={isMuted}
                        onToggleMute={handleToggleMute}
                        onEndCall={handleEndCall}
                        onHelp={handleHelp}
                    />
                </Paper>
            )}
            <HelpDialog
                open={helpDialogOpen}
                onClose={handleCloseHelp}
                simulationType={simulationType}
            />
            <CountdownDialog open={showCountdown} onClose={() => setShowCountdown(false)} />
        </Box>
    );
}

export default SimulationPage;