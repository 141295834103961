import React, { useState, useEffect, useCallback, useRef, useMemo } from 'react';
import { Dialog, DialogTitle, DialogContent, DialogActions, Button, Typography, Box, FormControl, InputLabel, Select, MenuItem, FormHelperText } from '@mui/material';

const PersonaProfileSelection = ({ open, onClose, onSubmit, userProfile, isLoading }) => {
    const mountedRef = useRef(true);
    const [touched, setTouched] = useState({});
    const [selections, setSelections] = useState({
        targetIndustry: '',
        targetSubIndustry: '',
        companySize: '',
        decisionMakerLevel: '',
        decisionMakerDepartment: '',
        geographicFocus: {
            type: userProfile?.profile?.targetCustomer?.geographicFocus?.geographicFocus || 'None',
            selection: ''
        }
    });

    const [errors, setErrors] = useState({});
    const [hasAttemptedSubmit, setHasAttemptedSubmit] = useState(false);

    useEffect(() => {
        return () => {
            mountedRef.current = false;
        };
    }, []);

    // Memoize available options from user profile
    const availableOptions = useMemo(() => {
        const { targetCustomer = {} } = userProfile?.profile || {};
        return {
            targetIndustries: targetCustomer.targetIndustries || [],
            companySizes: targetCustomer.companySizes || [],
            decisionMakerLevels: targetCustomer.decisionMakerLevels || [],
            decisionMakerDepartments: targetCustomer.decisionMakerDepartments || [],
            geographicFocus: targetCustomer.geographicFocus || { geographicFocus: 'None', regions: [], states: [] }
        };
    }, [userProfile]);

    // Get sub-industries based on selected target industry
    const availableSubIndustries = useMemo(() => {
        if (!selections.targetIndustry) return [];
        const selectedIndustry = availableOptions.targetIndustries.find(
            industry => industry.targetIndustry === selections.targetIndustry
        );
        return selectedIndustry?.targetSubIndustries || [];
    }, [selections.targetIndustry, availableOptions.targetIndustries]);

    // Memoize department requirement check
    const isDepartmentRequired = useMemo(() => {
        return selections.decisionMakerLevel && 
            !['Startup Founder', 'Business Owner'].includes(selections.decisionMakerLevel);
    }, [selections.decisionMakerLevel]);

    const validateField = useCallback((field, value) => {
        switch (field) {
            case 'targetIndustry':
                return !value ? 'Target industry is required' : '';
            case 'companySize':
                return !value ? 'Company size is required' : '';
            case 'decisionMakerLevel':
                return !value ? 'Decision maker level is required' : '';
            case 'decisionMakerDepartment':
                return isDepartmentRequired && !value ? 'Department is required' : '';
            case 'geographicFocus':
                const geoFocusType = availableOptions.geographicFocus.geographicFocus;
                if ((geoFocusType === 'Regional' || geoFocusType === 'State') && !value) {
                    return `Please select a ${geoFocusType.toLowerCase()}`;
                }
                return '';
            default:
                return '';
        }
    }, [isDepartmentRequired, availableOptions.geographicFocus.geographicFocus]);

    const validateSelections = useCallback(() => {
        if (!mountedRef.current) return false;

        const requiredFields = ['targetIndustry', 'companySize', 'decisionMakerLevel'];
        const newErrors = {};
        
        requiredFields.forEach(field => {
            const fieldValue = field === 'geographicFocus' ? 
                selections[field].selection : 
                selections[field];
            const error = validateField(field, fieldValue);
            if (error) newErrors[field] = error;
        });

        if (isDepartmentRequired) {
            const deptError = validateField('decisionMakerDepartment', selections.decisionMakerDepartment);
            if (deptError) newErrors.decisionMakerDepartment = deptError;
        }

        if (selections.geographicFocus.type !== 'None') {
            const geoError = validateField('geographicFocus', selections.geographicFocus.selection);
            if (geoError) newErrors.geographicFocus = geoError;
        }

        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    }, [selections, isDepartmentRequired, validateField]);

    // Handle selection changes
    const handleChange = useCallback((field) => (event) => {
        if (!mountedRef.current) return;

        const value = event.target.value;
        setSelections(prev => {
            const newSelections = { ...prev };

            if (field === 'geographicFocus') {
                newSelections.geographicFocus = {
                    ...prev.geographicFocus,
                    selection: value
                };
            } else {
                newSelections[field] = value;
                
                // Reset dependent fields
                if (field === 'targetIndustry') {
                    newSelections.targetSubIndustry = '';
                }
                if (field === 'decisionMakerLevel') {
                    newSelections.decisionMakerDepartment = '';
                    // Remove the touched state for department when level changes
                    setTouched(prev => ({
                        ...prev,
                        decisionMakerDepartment: false
                    }));
                }
            }
            
            return newSelections;
        });
        setTouched(prev => ({ ...prev, [field]: true }));
        
        // Only validate this field if it was previously showing an error
        if (errors[field]) {
            const error = validateField(field, value);
            setErrors(prev => ({
                ...prev,
                [field]: error
            }));
        }
    }, [errors, validateField]);

    const handleBlur = useCallback((field) => () => {
        if (!mountedRef.current) return;

        setTouched(prev => ({ ...prev, [field]: true }));
        
        // Validate only the blurred field
        const fieldValue = field === 'geographicFocus' ? 
            selections[field].selection : 
            selections[field];
        const error = validateField(field, fieldValue);
        
        setErrors(prev => ({
            ...prev,
            [field]: error
        }));
    }, [selections, validateField]);

    const handleSubmit = useCallback(() => {
        setHasAttemptedSubmit(true);
        const isValid = validateSelections();
        if (!isValid) {
            const requiredFields = ['targetIndustry', 'companySize', 'decisionMakerLevel'];

            if (isDepartmentRequired) {
                requiredFields.push('decisionMakerDepartment');
            }

            if (selections.geographicFocus.type !== 'None') {
                requiredFields.push('geographicFocus');
            }

            setTouched(requiredFields.reduce((acc, field) => ({ ...acc, [field]: true }), {}));
            return;
        }

        onSubmit(selections);
    }, [selections, validateSelections, onSubmit, isDepartmentRequired]);

    const shouldShowError = useCallback((field) => {
        return (touched[field] || hasAttemptedSubmit) && errors[field];
    }, [touched, hasAttemptedSubmit, errors]);

    const renderField = useCallback((field, label, options, required = true) => (
        <FormControl 
            error={Boolean(shouldShowError(field))}
            required={required}
            disabled={isLoading}
            fullWidth
        >
            <InputLabel>{label}</InputLabel>
            <Select
                value={field === 'geographicFocus' ? selections.geographicFocus.selection : selections[field]}
                onChange={handleChange(field)}
                onBlur={handleBlur(field)}
                label={label}
            >
                {options.map((option) => (
                    <MenuItem 
                        key={typeof option === 'string' ? option : option.targetIndustry} 
                        value={typeof option === 'string' ? option : option.targetIndustry}
                    >
                        {typeof option === 'string' ? option : option.targetIndustry}
                    </MenuItem>
                ))}
            </Select>
            {shouldShowError(field) && <FormHelperText>{errors[field]}</FormHelperText>}
        </FormControl>
    ), [selections, errors, handleChange, handleBlur, isLoading, shouldShowError]);

    return (
        <Dialog 
            open={open} 
            onClose={onClose}
            maxWidth="md"
            fullWidth
        >
            <DialogTitle>
                <Box>
                    <Typography component="span" variant="h4">
                        Customize Your Simulation
                    </Typography>
                    <Typography variant="body2" color="text.secondary" sx={{ mt: 1 }}>
                        Select options from your profile to generate a targeted persona
                    </Typography>
                </Box>
            </DialogTitle>

            <DialogContent>
                <Box sx={{ mt: 2, display: 'flex', flexDirection: 'column', gap: 3 }}>
                    {renderField('targetIndustry', 'Target Industry', availableOptions.targetIndustries)}
                    
                    {selections.targetIndustry && availableSubIndustries.length > 0 && (
                        renderField('targetSubIndustry', 'Target Sub-Industry', availableSubIndustries, false)
                    )}
                    
                    {renderField('companySize', 'Company Size', availableOptions.companySizes)}
                    {renderField('decisionMakerLevel', 'Decision Maker Level', availableOptions.decisionMakerLevels)}
                    
                    {isDepartmentRequired && (
                        renderField('decisionMakerDepartment', 'Department', availableOptions.decisionMakerDepartments)
                    )}

                    {availableOptions.geographicFocus.geographicFocus === 'Regional' && (
                        renderField('geographicFocus', 'Select Region', availableOptions.geographicFocus.regions)
                    )}

                    {availableOptions.geographicFocus.geographicFocus === 'State' && (
                        renderField('geographicFocus', 'Select State', availableOptions.geographicFocus.states)
                    )}
                </Box>
            </DialogContent>

            <DialogActions sx={{ p: 3 }}>
                <Button onClick={onClose} disabled={isLoading}>
                    Cancel
                </Button>
                <Button 
                    onClick={handleSubmit} 
                    variant="contained" 
                    disabled={isLoading}
                >
                    Continue
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default React.memo(PersonaProfileSelection);
